import Axios from "axios";
import React, { useState } from "react";
import { Autocomplete } from "react-materialize";

type Props = {
  shops: { [key: string]: undefined }
  srcShopName: string
  destShopName: string
}

const MoveFavoritesInput = ({ shops, srcShopName, destShopName }: Props) => {
  const [srcDisplayFavoriteCount, setSrcDisplayFavoriteCount] = useState<string | undefined>();
  const [destDisplayFavoriteCount, setDestDisplayFavoriteCount] = useState<string | undefined>();

  const fetchCountFavorite = async (option: string) => {
    const shopId = option.split('：')[0];
    const { data: count } = await Axios.get<number>(`move_favorite/favorite_count?shop_id=${shopId}`);
    return count;
  };

  return (
    <div className="mt-10">
      移行元
      <Autocomplete
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={srcShopName}
        required
        options={{
          data: shops,
          onAutocomplete: async (option) => {
            setSrcDisplayFavoriteCount(undefined);
            const count = await fetchCountFavorite(option);
            setSrcDisplayFavoriteCount(`${option}( お気に入り：${count} )`);
          },
        }}
      />
      移行先
      <Autocomplete
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={destShopName}
        required
        options={{
          data: shops,
          onAutocomplete: async (option) => {
            setDestDisplayFavoriteCount(undefined);
            const count = await fetchCountFavorite(option);
            setDestDisplayFavoriteCount(`${option}( お気に入り：${count} )`);
          },
        }}
      />
      {
        (srcDisplayFavoriteCount != null || destDisplayFavoriteCount != null) && (
          <>
            <h6>{srcDisplayFavoriteCount ?? '未選択'}</h6>
            <div className="mt-3">から</div>
            <h6>{destDisplayFavoriteCount ?? '未選択'}</h6>
            へ移行します
          </>
        )
      }

    </div>
  );
};

export default MoveFavoritesInput;
